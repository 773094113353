.Dashboard__Document * {
  font-size: 12pt;
  font-family: "Times New Roman";
}

.Dashboard__Document {
  width: 100%;
  flex-grow: 1;
  background-color: transparent !important;
}

.Dashboard__DocumentStart {
  font-size: 12pt;
  font-family: "Times New Roman";
}

.Dashboard__DocumentTitle {
  font-size: 24px;
  line-height: 30px;
  margin-top: -15px !important;
  margin-bottom: 1.3rem;
}

@media screen and (max-width: 768px) {
  .Dashboard__DocumentTitle {
    font-size: 18px;
    line-height: 1.25;
  }
}
