.CompassTopicsWidget {
  font-size: 14px;
}

.CompassTopicsWidget__List {
  margin-bottom: 0px;
  padding-left: 0;
  list-style: none;
}

.CompassTopicsWidget__List li {
  font-size: 12px;
  line-height: 1.5;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid hsla(230, 50%, 95%, 1);
}

.CompassTopicsWidget__List li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.CompassTopicsWidget__Breadcrumb {
  color: #262626;
}
