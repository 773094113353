html {
  background-color: #f8f9fe;
}

body {
  color: #262626;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App h3 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  color: #ff885a;
  letter-spacing: 2px;
  line-height: 1.8em;
}

.App code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ReactModalPortal button,
.App button {
  border: 0;
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}

.App button:focus,
.App button::-moz-focus-inner,
.App input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
}

.react-draggable-transparent-selection {
  user-select: none;
}

.react-grid-item.react-grid-placeholder {
  background: hsl(230, 75%, 96%);
  /* opacity: 0.1; */
  transition-duration: 100ms;
  z-index: 0;
  border-radius: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0px;
  right: 0px;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 5px 5px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  z-index: 11;
  visibility: hidden;
}

.react-resizable:hover .react-resizable-handle {
  visibility: visible;
}

.page-id-546 .et_pb_row--with-menu {
  display: flex;
  align-items: center;
  max-width: none;
  width: calc(100% - 60px);
}

.page-id-546 .et_pb_section--with-menu .et_pb_button {
  visibility: hidden;
}

.page-id-546 .et_pb_row--with-menu > .et-last-child {
  width: auto;
  display: none;
}

.page-id-546 .et_pb_row--with-menu > .et_pb_column {
  margin-bottom: 0;
}

.page-id-546 .et_builder_inner_content {
  z-index: 10 !important;
}

.page-id-546 .et-l--header {
  position: sticky;
  top: 0;
  z-index: 100;
}

.page-id-546 .et-l--footer {
  position: relative;
  z-index: 1;
  font-size: 16px;
}

.page-id-546 #mobile_menu1.et_mobile_menu {
  background-color: white !important;
}

#myjr-auth-dropdown {
  display: flex;
}

.AuthDropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  line-height: 1.25;
}

.AuthDropdown__Inner {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px hsl(230deg 50% 50% / 10%);
  overflow: hidden;
  padding: 15px;
  width: 240px;
}

.AuthDropdown__Inner > div:first-child {
  font-size: 12px;
  margin-bottom: 10px;
  word-break: break-word;
  text-align: center;
}

.AuthDropdown__Inner > div:nth-child(3) {
  font-size: 12px;
  color: #666;
  text-align: center;
  margin: 5px;
}

.AuthDropdown__Inner > div:first-child > span {
  font-size: 12px;
  font-weight: bold;
}

.AuthDropdown__Inner button {
  background-image: linear-gradient(135deg, #b400e1, #1ca0ea);
  border-color: transparent;
  border-radius: 8px;
  box-shadow: 0 3px 10px -5px rgb(9 28 79 / 50%);
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  padding: 8px 25px;
  position: relative;
  transition: all 0.2s;
  border: none;
  display: block;
  width: 100%;
}

.AuthDropdown__Toggle {
  all: unset;
  color: inherit;
  cursor: pointer;
  display: flex;
}

.AuthDropdown__Toggle:hover {
  color: #666;
}

.AuthDropdown__Toggle svg {
  width: 36px;
  height: 36px;
}

@media (min-width: 768px) {
}

@media (min-width: 981px) {
  .page-id-546 .et_pb_column--with-menu {
    flex-grow: 1;
    margin-right: 30px;
  }

  .page-id-546 .et-l--header {
    position: static;
  }
}
