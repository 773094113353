:root {
  --grid-gap: 1rem;
  --widget-padding: 1rem;
  --widget-height: 32rem;
  --compass-topics-height: 15rem;
}

.StaticGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: var(--grid-gap);
  width: 100%;
  margin-top: 1rem;
}

.StaticGrid > *:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  height: var(--widget-height);
}

.StaticGrid > *:nth-child(2) {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  height: var(--widget-height);
}

.StaticGrid > *:nth-child(3) {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  height: var(--compass-topics-height);
}

.grid-item {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 10px 0px hsla(230, 50%, 50%, 0.1);
  overflow: hidden;
  padding: var(--widget-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.GenerateSummaryWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.CaseDocumentWidget {
  overflow-y: auto;
}

.CompassTopicsWidget {
  overflow-y: auto;
}
