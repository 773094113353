.App {
  line-height: 1.25;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App a {
  text-decoration: underline;
}

.App *:focus {
  outline: 2px solid #95ccff;
}

.App h1:not(.Dashboard__Document *),
.App h2:not(.Dashboard__Document *),
.App h3:not(.Dashboard__Document *),
.App h4:not(.Dashboard__Document *),
.App h5:not(.Dashboard__Document *),
.App h6:not(.Dashboard__Document *) {
  color: unset;
  font-weight: 600;
  padding-bottom: 0;
}

.Dashboard__Document h1,
.Dashboard__Document h2,
.Dashboard__Document h3,
.Dashboard__Document h4,
.Dashboard__Document h5,
.Dashboard__Document h6 {
  color: unset;
  font-weight: 600;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: none;
  font-size: initial;
}

.AuthPage h1,
.AuthPage h2,
.AuthPage h3,
.AuthPage h4,
.AuthPage h5,
.AuthPage h6 {
  color: unset;
  font-weight: 600;
  padding-bottom: 0;
}

.AuthPage h3,
.App h3:not(.Dashboard__Document h3) {
  font-family: "Poppins", "Inter", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  color: #ff885a;
  letter-spacing: 2px;
  line-height: 1.8em;
}

.App h4:not(.Dashboard__Document h4) {
  font-size: 16px;
}

.Main > * {
  padding: 0 15px;
}

.Logo {
  width: 160px;
}

.Container {
  padding: 0 30px;
  max-width: 600px;
  line-height: 1.5;
  margin: 0 auto;
}

.Section {
  padding: 40px 0;
}

.Section:not(.Section--Primary):not(.Section--Secondary) + .Section:not(.Section--Primary):not(.Section--Secondary) {
  padding-top: 0;
}

.Section--FullHeight {
  box-sizing: border-box;
  height: 100vh;
}

.Section--Large {
  padding: 80px 0;
}

.Section--Primary {
  background-image: linear-gradient(135deg, #b400e1, #1ca0ea);
  color: white;
}

.Section--Secondary {
  background-color: hsla(230, 25%, 25%, 1);
  color: white;
}

.NavBar {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background-color: #f8f9fe;
  transition-duration: 0.15s;
  height: 70px;
  box-sizing: border-box;
}

.NavBar a,
.NavBar button {
  font-size: 14px;
  font-weight: bold;
  color: hsla(230, 10%, 50%, 0.85);
  transition-duration: 0.1s;
  text-decoration: none;
}

.NavBar a.NavBar__Logo {
  padding: 0;
  margin: 0;
}

.NavBar button:hover,
.NavBar a:hover,
.NavBar a.NavBar__Nav--Active {
  color: hsla(230, 10%, 40%, 1);
}

.NavBar--Sticky {
  background: white;
}

.NavBar__Left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NavBar__Left a {
  margin-left: 30px;
}

.NavBar__Nav {
  margin-bottom: 4px;
}

.NavBar__Right {
  flex-grow: 1;
  margin-left: 45px;
  margin-bottom: 4px;
  justify-content: flex-end;
  align-items: stretch;
  display: flex;
}

.NavBar__RightNav {
  display: flex;
  align-items: center;
}

.NavBar__AuthButton {
  cursor: pointer;
  margin-left: 25px;
}

.BasicPage {
  padding: 0;
}

.BasicPage__Header {
  text-align: center;
}

.BasicPage__Header h1 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 2.5em;
}

.BasicPage__Header h2 {
  margin-top: 0;
  margin-bottom: 15px;
}

.BasicPage__Header h3 {
  margin-top: 0;
  margin-bottom: 0px;
  color: white;
}

.BasicPage__Header p {
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 1.25em;
}

.AuthPage {
  box-sizing: border-box;
  overflow: auto;
  min-height: 600px;
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 30px;
  background-image: linear-gradient(355deg, #fff 54.75%, transparent 55%),
    linear-gradient(353deg, #bc9bf3 54.75%, transparent 55%), linear-gradient(357deg, #84cbf3 54.75%, transparent 55%),
    linear-gradient(135deg, #b400e1, #1ca0ea);
}

.AuthPage .Section--FullHeight {
  display: flex;
  justify-content: center;
}

.AuthForm {
  margin: auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 475px;
  position: relative;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 1px 10px 0px hsla(230, 50%, 50%, 0.1);
  color: #262626;
  z-index: 1;
  padding: 30px;
  text-align: center;
}

.AuthForm__Subheader {
  margin: 0;
  margin-bottom: 5px;
}

.AuthForm__Header {
  font-weight: bold;
  margin: 0;
  margin-bottom: 15px;
}

.AuthPage .Logo {
  width: 120px;
  margin: 30px auto 0;
}

.AuthForm input {
  width: 100%;
  box-sizing: border-box;
  background-color: #f6f7fe;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  animation-duration: 0.5s;
}

.AuthForm button {
  background-image: linear-gradient(135deg, #b400e1, #1ca0ea);
  border-color: transparent;
  border-radius: 10000px;
  box-shadow: 0 3px 10px -5px rgb(9 28 79 / 50%);
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  padding: 8px 25px;
  position: relative;
  transition: all 0.2s;
  margin-top: 15px;
  border: none;
}

.AuthForm p {
  line-height: normal;
  font-size: 14px;
  margin-bottom: 1em !important;
}

.AuthForm .AuthForm__PrimaryAction {
  margin-top: 0px;
}

.AuthForm__SecondaryAction {
  margin-top: 10px;
}

.AuthForm__ForgotPassword {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
  display: inline-block;
}

.AuthForm--Register > div:first-of-type {
  flex-wrap: wrap;
  justify-content: space-between;
}

.AuthForm--Register input:nth-child(1),
.AuthForm--Register input:nth-child(2),
.AuthForm--Register input:nth-child(7),
.AuthForm--Register input:nth-child(8) {
  width: calc(50% - 5px);
}

.error-message {
  color: red;
}
.success-message {
  color: #27cb70;
}

input:disabled {
  opacity: 50%;
}
