.Search {
  flex-grow: 1;
  position: relative;
  color: hsla(230, 10%, 50%, 0.85);
}

.SearchInput__Form {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
}

.SearchInput__Icon {
  position: absolute;
  left: 12px;
  top: 7px;
  width: 16px;
  height: 16px;
}

.App button.SearchInput__Close {
  color: inherit;
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 24px;
  line-height: 18px;
  width: 18px;
  height: 18px;
}

.App button.SearchInput__Close:hover {
  color: #00aeef;
  cursor: pointer;
}

.App button.SearchInput__Close:focus {
  outline: none !important;
  color: #00aeef;
}

.SearchInput {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 100px;
  padding: 6px 26px 6px 32px;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-size: 12px;
  border: 2px solid #cfd0d6;
}

.SearchInput:focus {
  outline: none;
  border-color: #00aeef;
}

.SearchInput::placeholder {
  color: hsla(230, 10%, 50%, 0.85);
}

.SearchInput__Container:focus-within {
  color: hsla(230, 10%, 50%, 0.85);
}

.SearchInput__Container .SearchInput:focus {
  border-color: hsla(230, 10%, 50%, 0.85);
}

.Search__Results {
  position: absolute;
  top: 40px;
  /* left: -90px; */
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 10px 15px 5px hsl(230deg 50% 50% / 10%);
  z-index: 100;
  max-height: 270px;
  overflow: auto;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Search__Results::-webkit-scrollbar {
  display: none;
}

.Search__Results .Citations {
  margin-top: 0;
  margin-bottom: 0;
}

.Search__Results .Citations li {
  transition-duration: 0.1s;
}

.Search__Results .Citations li:last-child {
  padding-bottom: 15px;
}

.Search__Results .Citations button {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  font-family: "Poppins", "Inter", Helvetica, Arial, Lucida, sans-serif;
  text-align: left;
}

.Search__Results .Citations button em {
  color: hsla(230, 10%, 25%, 0.85);
  font-style: initial;
}

.Search__Results .Citations button {
  cursor: pointer;
}

.Search__Results .Citations button:hover,
.Search__Results .Citations button:focus {
  outline: 0;
  background: #00aeef;
  color: white;
}

.Search__Results .Citations button:hover em,
.Search__Results .Citations button:focus em {
  color: white;
}

.App .Search__Results button {
  padding: 15px;
}

.App .Search__Results button {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.CountryToggle {
  display: inline-flex;
  position: relative;
  background-color: transparent;
  border-radius: 100px;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-size: 12px;
  border: 2px solid #cfd0d6;
  width: 96px;
  margin-right: 10px;
}

.CountryToggle:focus-within {
  border-color: #00aeef;
}

.CountryToggle > button {
  text-align: center;
  width: 50%;
}

button.CountryToggle__Inner {
  position: relative;
  z-index: 10;
  cursor: pointer;
  margin: 3px;
  padding: 3px;
  border-radius: 100px;
  outline: none !important;
}

button.CountryToggle__Inner:first-of-type {
  margin-right: 0px;
}

button.CountryToggle__Inner:last-of-type {
  margin-left: 0px;
}

.CountryToggle::after {
  position: absolute;
  content: "";
  background-color: #00aeef;
  color: white;
  margin: 3px;
  padding: 3px;
  border-radius: 100px;
  width: calc(50% - 10px);
  height: calc(100% - 12px);
  transition-duration: 0.15s;
}

.CountryToggle--CA > button:first-child {
  color: white !important;
}

.CountryToggle--US > button:last-child {
  color: white !important;
}

.CountryToggle--US::after {
  transform: translateX(calc(100% + 1px));
}
