.Dashboard {
  padding: 0;
}

.Dashboard__Grid-Container > .ResponsiveGrid {
  overflow: hidden;
  transition-duration: 0.666s !important;
}
.Dashboard__Grid-Container-enter {
  opacity: 0;
}
.Dashboard__Grid-Container-enter-active,
.Dashboard__Grid-Container-enter-done {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.666s !important;
}
.Dashboard__Grid-Container-enter-active > .ResponsiveGrid,
.Dashboard__Grid-Container-enter-done > .ResponsiveGrid {
  transition-property: height;
}
.Dashboard__Grid-Container-exit {
  visibility: visible !important;
  opacity: 1;
}
.Dashboard__Grid-Container-exit > .ResponsiveGrid {
  height: 0 !important;
  transition-delay: 0.111s !important;
}
.Dashboard__Grid-Container-exit-active,
.Dashboard__Grid-Container-exit-done {
  visibility: visible !important;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.666s !important;
}
.Dashboard__Grid-Container-exit-active > .ResponsiveGrid,
.Dashboard__Grid-Container-exit-done > .ResponsiveGrid {
  height: 0 !important;
  transition-property: height;
}

.Dashboard__Header {
  position: relative;
  top: 0px;
  background-position: top left;
  background-size: 120%;
  background-image: linear-gradient(135deg, #b400e1, #1ca0ea);
  transition-duration: 0.5s;
  transition-property: background-size;
  color: white;
  z-index: 5;
  font-size: 12px;
  padding: 15px 25px;
}

.Dashboard__Header--Slim {
  display: none;
  position: fixed;
  top: 75px;
  left: 0;
  right: 0;
  background-position: top left;
  background-size: 120%;
  background-image: linear-gradient(135deg, #b400e1, #1ca0ea);
  transition-duration: 0.5s;
  transition-property: background-size;
  color: white;
  z-index: 5;
  font-size: 12px;
  padding: 15px 30px;
}

.Dashboard__Header--Slim span,
.Dashboard__Header--Slim a {
  font-weight: normal;
}

.Dashboard__Search {
  align-items: center;
  color: #73778c;
  background-color: #f8f9fe;
  box-sizing: border-box;
  display: flex;
  padding: 10px;
  height: 48px;
  justify-content: space-between;
  transition-duration: 0.15s;
  z-index: 10;
  border-radius: 1600px;
  margin-bottom: 15px;
  box-shadow: 0px 5px 15px 5px hsl(230deg 50% 50% / 15%);
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.NavBar--Sticky.Dashboard__Header--Slim {
  top: 0px;
  display: block;
  padding: 15px 30px;
  animation-name: slideDown;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  box-shadow: 0px 5px 15px 5px hsl(230deg 50% 50% / 15%);
  background-position: top right;
}

.NavBar--Sticky.Dashboard__Header--Slim .Dashboard__Inset {
  top: 0;
}

.NavBar--Sticky.Dashboard__Header--Slim .Dashboard__Spinner {
  width: 28px;
  height: 28px;
  border: 5px solid transparent;
  border-top-color: currentColor;
}

.NavBar--Sticky.Dashboard__Header--Slim .Dashboard__CitationInfo {
  display: flex;
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.Dashboard__Grid-Container {
  /* margin-top: 41px; */
}

.Dashboard__Summary > div:first-child {
  margin-bottom: 10px;
}

.Dashboard__Summary h1 {
  font-size: 18px;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0;
}

.Dashboard__Summary h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}

.Dashboard__Summary ul {
  padding-left: 0;
  list-style: none;
}

.Dashboard__Summary li {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.Dashboard__Summary li > a {
  color: white;
  text-decoration: none;
}

.Dashboard__Summary .Dashboard__SummaryCitation {
  margin-top: 10px;
}

.Dashboard__Header--Slim .Dashboard__CitationInfo a {
  display: inline-block;
}

.Dashboard__Header--Slim .Dashboard__CitationInfo svg,
.Dashboard__SummaryCitation svg {
  display: inline-block;
  margin-left: 0.25em;
  width: 0.75em;
  height: 0.75em;
  fill: white;
}

.Dashboard__SummaryCitation strong {
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.Dashboard__SummaryCitation strong + span {
  font-size: 12px;
}

.Dashboard__Summary a {
  color: white;
}

.Dashboard__CourtInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.Dashboard__CourtInfo > div:first-child {
  display: flex;
}

.Dashboard__CourtInfo > div:first-child > * {
  width: 50%;
}

.Dashboard__CourtInfo strong {
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.Dashboard__CourtInfo strong + span {
  font-size: 12px;
}

.Dashboard__CourtInfoSection {
  display: flex;
  margin-bottom: 10px;
  margin-right: 30px;
}

.Dashboard__CourtInfoSection > div:first-child {
  flex-shrink: 0;
  margin-right: 10px;
}

.Dashboard__Inset {
  position: absolute;
  top: 95px;
  left: 0;
  right: 0;
  bottom: 0;
}

.Dashboard__Loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.25s;
}

.Dashboard__Loading--Active {
  opacity: 0.85;
  pointer-events: all;
  z-index: 10;
}

.Dashboard__Spinner {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  display: inline-block;
  right: 0;
  bottom: 0;
  border: 8px solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  transition: all 0.2s;
  transition-delay: 0s;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(-50%);
}

.Dashboard__Loading--Active .Dashboard__Spinner {
  opacity: 1;
  animation: LoadingSpinner 1s ease infinite;
  animation-delay: 0.2s;
  transition-delay: 0.2s;
  visibility: visible;
}

@keyframes LoadingSpinner {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0turn);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(1turn);
  }
}

@media (min-width: 768px) {
  .Dashboard__Header {
    top: 0px;
    padding: 15px 30px;
  }

  .NavBar--Sticky.Dashboard__Header--Slim {
    top: 0px;
  }

  .Dashboard__Grid-Container {
    margin-top: 0;
    padding: 0 15px;
  }
}

@media (min-width: 981px) {
  .Dashboard__Header {
    font-size: 16px;
    padding: 30px 30px;
  }

  .Dashboard__CourtInfo {
    width: 500px;
  }

  .Dashboard__Search {
    height: 65px;
    padding: 10px 20px;
  }

  .Dashboard__Summary {
    display: flex;
    justify-content: space-between;
  }

  .Dashboard__Summary h1 {
    font-size: 30px;
  }

  .Dashboard__Summary > div:first-child {
    padding-right: 60px;
    margin-bottom: 0;
  }

  .NavBar--Sticky.Dashboard__Header--Slim .Dashboard__Summary > div:first-child {
    padding-right: 0;
  }

  .NavBar--Sticky.Dashboard__Header--Slim {
    top: 0px;
  }

  .NavBar--Sticky.Dashboard__Header--Slim h1 {
    font-size: 24px;
  }
}
